// src/components/Header.jsx
import React from 'react';
import companyLogo from '../assets/Snapium/Logo.png';

const Header = () => {
  return (
    <header className="bg-white shadow">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
      <img src={companyLogo} width={100} alt='logo' />
        <nav>
          <ul className="flex space-x-4">
            <li><a href="#home" className="hover:underline">Home</a></li>
            <li><a href="#pricing" className="hover:underline">Pricing</a></li>
            <li><a href="#blog" className="hover:underline">Blog</a></li>
            <li><a href="#contact" className="hover:underline">Contact</a></li>
          </ul>
        </nav>
        <div>
          <button className="px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded hover:bg-orange-600">Sign In</button>
          <button className="ml-2 px-4 py-2 text-sm font-medium text-orange-500 border border-orange-500 rounded hover:bg-orange-500 hover:text-white">Register</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
