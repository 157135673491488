// import { Link } from 'react-router-dom';
// import HeadshotGenerator from './HeadshotGenerator';
import groupImg from '../assets/Snapium/Group 172.png';
import HeadshotGenerator from './HeadshotGenerator';
const Hero = () => {
  return (
    <section id='hero'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
        {/* Left Item */}
        <div className='flex flex-col mb-32 space-y-12 md:w-1/2'>
          <div className="w-[550px] h-[117px] text-black text-[42px] font-bold font-['Roboto']">Transform Your Photos for Professional Success</div>
          <div className="w-[501px] h-[111px] text-black/opacity-70 text-xl font-normal font-['Roboto'] leading-10">Get a professionally edited photo for your job profile and LinkedIn</div>
          <div className='flex justify-center md:justify-start'>
            {/* <button class="flex items-center justify-center px-6 py-4 bg-black text-white rounded-lg">
              Upload Image
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0703 16V7.85L8.47031 10.45L7.07031 9L12.0703 4L17.0703 9L15.6703 10.45L13.0703 7.85V16H11.0703ZM6.07031 20C5.52031 20 5.04948 19.8042 4.65781 19.4125C4.26615 19.0208 4.07031 18.55 4.07031 18V15H6.07031V18H18.0703V15H20.0703V18C20.0703 18.55 19.8745 19.0208 19.4828 19.4125C19.0911 19.8042 18.6203 20 18.0703 20H6.07031Z" fill="#E8EAED" />
              </svg>
            </button> */}
            <HeadshotGenerator />

          </div>
        </div>
        {/* Image */}
        <div className='md:w-1/2'>
          <img src={groupImg} alt='group people' />
          {/* <HeadshotGenerator /> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;