// src/components/Pricing.jsx
import React from 'react';

const plans = [
  {
    name: 'Basic',
    price: '₹7.00/week',
    description: 'For individuals with one-time use',
  },
  {
    name: 'Premium',
    price: '₹15.00/week',
    description: 'For individuals with frequent use',
  },
  {
    name: 'Pro',
    price: '₹24.00/week',
    description: 'For businesses with regular use',
  },
];

const Pricing = () => {
  return (
    <section className="bg-white py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-12">Pricing Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div key={index} className="p-6 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-xl font-bold mb-4">{plan.price}</p>
              <p>{plan.description}</p>
              <button className="mt-4 px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded hover:bg-orange-600">Purchase</button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
