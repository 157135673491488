// src/components/Features.jsx
import React from 'react';

const features = [
  {
    title: 'Professional Pictures',
    description: 'Transform your photos with a professional look that stands out on job profiles and LinkedIn.',
    icon: '📸', // replace with actual icon
  },
  {
    title: 'Quick Turnaround',
    description: 'Receive your professionally edited photo within a few seconds, ready for your job profile or LinkedIn.',
    icon: '⏱️', // replace with actual icon
  },
  {
    title: 'Affordable Pricing',
    description: 'Enjoy high-quality photos without the expensive costs of traditional photography.',
    icon: '💸', // replace with actual icon
  },
  {
    title: 'Satisfaction Guarantee',
    description: 'We guarantee you’ll be satisfied with your photo. If not, we’ll make it right.',
    icon: '👍', // replace with actual icon
  },
  {
    title: 'Easy Upload Process',
    description: 'Upload your photo in seconds with our user-friendly interface, and let us handle the rest.',
    icon: '📤', // replace with actual icon
  },
  {
    title: 'Secure Handling',
    description: 'Your data and privacy are safe with us. We prioritize your privacy and security throughout the editing process.',
    icon: '🔒', // replace with actual icon
  },
];

const Features = () => {
  return (
    <section className="bg-white py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-12">What We Offer</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6 bg-gray-100 rounded shadow">
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-2xl font-semibold mb-2">{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
