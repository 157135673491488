import React, { useState } from 'react';

function HeadshotGenerator() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      handleUploadImage(file);
    }
  };

  const handleUploadImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://karanjakhar--professional-headshot-single-image-app.modal.run/upload', {
        method: 'POST',
        body: formData,
      });
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      setResultImage(url);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        {selectedImage && (
          <img src={URL.createObjectURL(selectedImage)} alt="Selected" width={200} className="mb-4 rounded-lg" />
        )}
        <div className="flex items-center justify-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="upload-input"
          />
          <label htmlFor="upload-input" className="flex items-center justify-center px-6 py-4 bg-black text-white rounded-lg cursor-pointer">
            {loading ? 'Processing...' : 'Select Image'}
            {!loading && (
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0703 16V7.85L8.47031 10.45L7.07031 9L12.0703 4L17.0703 9L15.6703 10.45L13.0703 7.85V16H11.0703ZM6.07031 20C5.52031 20 5.04948 19.8042 4.65781 19.4125C4.26615 19.0208 4.07031 18.55 4.07031 18V15H6.07031V18H18.0703V15H20.0703V18C20.0703 18.55 19.8745 19.0208 19.4828 19.4125C19.0911 19.8042 18.6203 20 18.0703 20H6.07031Z" fill="#E8EAED" />
              </svg>
            )}
          </label>
        </div>
        {resultImage && (
          <div>
            <h2 className="text-xl font-semibold mt-4">Result Image:</h2>
            <img src={resultImage} alt="Result" width={200} className="mt-2 rounded-lg" />
          </div>
        )}
      </div>
    </div>
  );
}

export default HeadshotGenerator;
