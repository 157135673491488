// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-10">
      <div className="container mx-auto text-center">
        <h3 className="text-xl font-bold mb-4">Snapium</h3>
        <p className="text-sm text-gray-600">© 2024 Snapium. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
