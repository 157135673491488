// src/components/FAQ.jsx
import React from 'react';

const faqs = [
  {
    question: 'How long does it take to receive my edited photo?',
    answer: 'You will receive your edited photo within a few seconds after uploading.',
  },
  {
    question: 'How do I upload my photo?',
    answer: 'Simply click the "Upload Image" button and select the photo you want to edit.',
  },
  {
    question: 'What if I am not satisfied with my edited photo?',
    answer: 'We offer a satisfaction guarantee. If you are not satisfied, we will make it right.',
  },
  {
    question: 'Do you offer bulk editing services?',
    answer: 'Yes, we offer bulk editing services for businesses. Please contact us for more information.',
  },
  {
    question: 'How can I contact customer support if I have more questions?',
    answer: 'You can contact our customer support team via the "Contact" page on our website.',
  },
];

const FAQ = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white p-6 rounded shadow">
              <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
