// src/components/HowItWorks.jsx
import React from 'react';

const steps = [
  {
    number: 1,
    title: 'Register',
    description: 'Sign up and create an account to get started.',
  },
  {
    number: 2,
    title: 'Generate',
    description: 'Upload your photo and let our AI do the magic.',
  },
  {
    number: 3,
    title: 'Download',
    description: 'Download your professionally edited photo.',
  },
];

const HowItWorks = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="p-6 bg-white rounded shadow">
              <div className="text-4xl font-bold mb-4">{step.number}</div>
              <h3 className="text-2xl font-semibold mb-2">{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
